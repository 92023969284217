import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Pharmacies} from '../../components/svgs/industries';

const PharmacyIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query PharmacyIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "PWF1ScawoaaxnKIgjOtuc"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Pharmacies/>}>
      <Seo 
      title="Affichage dynamqiue pour pharmacies et salles d'attente"
      description="Éduquez et informez votre clientèle sur les enjeux de santé tout en faisant la promotion de vos services avec le système de gestion de vos affichages numérique mis en place par Attractif. "
       />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default PharmacyIndustryPageFr
